import React from 'react';

import './Loading.css';
import killua from './../assets/icons/killua.png';


const Loading = () => {
    return (
        <div className='loading-container'>
            <link rel="preload" href={killua} as="image" />
            <img className='loading-killua' src={killua} alt='Killua'></img>
            <div>Loading layout...</div>
        </div>
    );
}

export default Loading;
