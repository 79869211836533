import React, { useEffect, useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import './App.css';
import Constants from './constants.json';

import Loading from './components/Loading';

const Layout = lazy(() => import('./components/Layout'));
const HomePage = lazy(() => import('./pages/HomePage'));
const About = lazy(() => import('./pages/About'));
const Experiences = lazy(() => import('./pages/Experiences'));
const Photography = lazy(() => import('./pages/Photography'));
const Intro = lazy(() => import('./pages/Intro'));
const Visitor = lazy(() => import('./pages/Visitor'));

// function DebugRouter({ children }) {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [previousPath, setPreviousPath] = useState(location.pathname);

//   useEffect(() => {
//     setPreviousPath(location.pathname);
//   }, [location, navigate, previousPath]);

//   return children;
// }

const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
};


function App() {
  useEffect(() => {
    // Send a request to the backend to track the visitor
    axios.get(`/api/visit`) 
      .then(response => {
        console.log('Visitor tracked successfully');
      })
      .catch(error => {
        console.error('Error tracking visitor', error);
      });
  }, []);
  
  return (
    <Router>
      {/* <DebugRouter> */}
        <Suspense fallback={<Loading />}>
            <Layout>
                <ScrollToTop />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/intro" element={<Intro />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/experiences" element={<Experiences />} />
                        <Route path="/photography" element={<Photography />} />
                        <Route path="/visitor" element={<Visitor />} />
                    </Routes>
            </Layout>
        </Suspense>
      {/* </DebugRouter> */}
    </Router>
  );
}

export default App;